import logo from './logo125.png';
import TextAnalyzer from './pages/TextAnlz';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <TextAnalyzer></TextAnalyzer>
      
          <p style={{ fontSize: '16px' }}>Created by Yi Wang (Corey)</p>
      </header>
    </div>
  );
}

export default App;
